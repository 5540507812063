import React, { useEffect, useState } from "react";

export default function Notification({ type, message, duration }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => setShow(false), duration * 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  return show ? (
    <div className={`alert alert-${type}`} role="alert">
      {message}
    </div>
  ) : null;
}

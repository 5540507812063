import React from "react";

//components
import { CardA } from "./../ui/Cards";
import "antd/dist/antd.css";

const WizardDevice = ({ step, steps, changeStep, pageID }) => {
  const [warning, setWarning] = React.useState(<></>);

  const handleDeviceChange = (device) => {
    console.log(device);
  };

  if (step !== pageID) {
    return null;
  }

  return (
    <CardA
      title='Which device are you using?'
      subtitle='Click the button that corresponds to your device'
      steps={steps}
      step={step - 1}
    >
      {warning}

      <div className='row mt-5'>
        <button
          type='button'
          className='col-4 btn btn-primary mx-auto w-25'
          onClick={() => changeStep("forward")}
        >
          Wasatch 785
        </button>
        <button
          type='button'
          className='col-4 btn btn-primary mx-auto w-25'
          onClick={() => changeStep("forward")}
          //   disabled={!sample?.Device}
        >
          CORA100
        </button>
      </div>
    </CardA>
  );
};

export default WizardDevice;

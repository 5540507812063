import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { userActions } from "../../actions/auth";
import { history } from "../../helpers/history";

// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useUserLocations, useUserSettings, useUserId } from "../../api/user";
//Elements and CSS from AntDesign
import "../../styles/Navbar.css";

import { Link } from "react-router-dom";

// import Login from './Login'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const DropdownMenu = ({ authenticated }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const { data: locations } = useUserLocations();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const { data: settings } = useUserSettings();
  const {
    data: userId,
    isLoading: userIdIsLoading,
    isError: userIdIsError,
  } = useUserId();

  const queryClient = useQueryClient();

  const setLocation = useMutation(
    (location) =>
      axios.post("/user/post-location", {
        userId: userId,
        locationId: location,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["settings"]);
        // This will refetch every query with "byLoc" at the start of the key
        queryClient.refetchQueries("byLoc");
      },
    }
  );

  useEffect(() => {
    // Check browser storage for authentication status
    const storedAuthStatus = localStorage.getItem("isAuthenticated");
    if (storedAuthStatus === "true") {
      // If authenticated, dispatch appropriate action
      dispatch(userActions.loginSuccess());
    }
    if (locations) {
      setLocationList(locations);
      if (!selectedLocation) {
        setSelectedLocation(locations[0].name);
      }
    }
  }, [dispatch, locations]);

  const handleLogout = () => {
    dispatch(userActions.logout());
  };

  async function handleLocationChange(id) {
    setLocation.mutate(id, {
      onError: (error) => {
        console.log(error);
      },
    });
  }

  const onLocationSelect = (loc) => {
    setSelectedLocation(loc.name);
    handleLocationChange(loc.locationId);
  };

  return (
    <li className='nav-item d-flex'>
      <div className='dropdown m-auto'>
        <button
          className='dropdown-toggle'
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
          id='dropdownMenuLoc'
        >
          {/* <FontAwesomeIcon  */}
          {/* id="menu-icon"  */}
          {/* icon={faBars} */}
          {/* style={{ width: 15, height: 15, marginRight: 4 }} */}
          {/* /> */}
          {selectedLocation}
        </button>
        <ul className='dropdown-menu'>
          {locationList && locationList.length > 1 && (
            <>
              <li>
                <h6 className='dropdown-header'>Locations</h6>
              </li>
              {locationList.map((loc, index) => (
                <li
                  key={loc.locationId}
                  className='dropdown-item'
                  onClick={(e) => {
                    e.preventDefault();
                    onLocationSelect(loc);
                  }}
                >
                  {loc.name}
                </li>
              ))}
              <li>
                <hr className='dropdown-divider'></hr>
              </li>
            </>
          )}
          <li className='logout mx-auto'>
            <Link
              className='dropdown-item m-auto'
              type='button'
              to='/login'
              onClick={handleLogout}
            >
              Logout
              <FontAwesomeIcon
                className='dropdown-icon'
                id='logout-icon'
                icon={faArrowRightFromBracket}
              />
            </Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default DropdownMenu;

import { useMutation, useQueryClient } from "react-query";
import { edit } from "./logic";

export function useSampleEdit(id) {
  const queryClient = useQueryClient();
  return useMutation(edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(["sample", id]);
      queryClient.invalidateQueries("recentSamples");
    },
    onError: (error) => {
      console.error("Error: ", error);
    },
  });
}

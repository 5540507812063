// import axios from 'axios';
import Cookies from "universal-cookie";
export const userService = {
  login,
  logout,
  handleResponse,
};
const cookies = new Cookies();
function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };
  return fetch(process.env.REACT_APP_API_URL + "/user/login", requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user.token) {
        cookies.set("JWT", JSON.stringify(user.token), {
          expires: new Date(Date.now() + 21600000),
          path: "/",
          domain: "spectraplasmonics.com",
        });
        cookies.set("type", "user", {
          expires: new Date(Date.now() + 21600000),
          path: "/",
          domain: "spectraplasmonics.com",
        });
      }
      return user;
    });
}

function logout() {
  localStorage.removeItem("token");
  cookies.remove("JWT", { path: '/', domain: 'spectraplasmonics.com' });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

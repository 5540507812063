import { createContext, useContext, useState } from "react";

const ReachbackContext = createContext();

export const ReachbackProvider = ({ children }) => {
  // const [submittedErrors, setSubmittedErrors] = useState(null);
  const [submittedErrorsT, setSubmittedErrorsT] = useState(false);
  const [submittedErrorsB, setSubmittedErrorsB] = useState(false);

  const updateSubmittedErrorsT = (errors) => {
    // setSubmittedErrors(errors);
    setSubmittedErrorsT(errors);
  };

  const updateSubmittedErrorsB = (errors) => {
    // setSubmittedErrors(errors);
    setSubmittedErrorsB(errors);
  };

  return (
    <ReachbackContext.Provider
      value={{
        // submittedErrors,
        submittedErrorsT,
        submittedErrorsB,
        updateSubmittedErrorsT,
        updateSubmittedErrorsB,
      }}
    >
      {children}
    </ReachbackContext.Provider>
  );
};

export const useReachbackContext = () => {
  return useContext(ReachbackContext);
};

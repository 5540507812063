import React from "react";
import { CardA } from "../../ui/Cards";

const WizardCalibrateSucess = ({
  step,
  steps,
  setIsCalibrate,
  changeStep,
  pageID,
}) => {
  if (step !== pageID) {
    return null;
  }
  return (
    <CardA
      title='Calibration Successful'
      subtitle='Continue to new sample'
      // steps={steps}
      // step={step}
    >
      <div className='row mt-5'>
        <button
          type='button'
          className='btn btn-success mx-auto w-25'
          onClick={() => {
            changeStep("forward");
            setIsCalibrate(false);
          }}
        >
          New Sample
        </button>
      </div>
    </CardA>
  );
};

export default WizardCalibrateSucess;

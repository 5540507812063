import React from "react";
import { Stepper } from "react-form-stepper";

export function CardA({
  icon = "",
  title = "",
  subtitle = "",
  help = "",
  children,
  steps,
  step = "",
}) {
  return (
    <div className='shadow card'>
      <div className='card-body'>
        {icon !== "" && <i className={"float-end mt-1 " + icon}></i>}
        <h5 className='card-title w-75'>
          {title}
          {help !== "" && (
            <span className='ms-2' style={{ fontSize: "1rem" }}>
              <i
                className='far fa-question-circle text-primary'
                data-toggle='tooltip'
                data-placement='top'
                title={help}
                style={{ cursor: "pointer" }}
              ></i>
            </span>
          )}
          {/* {step !== "" && (
            <span style={{ float: "right" }}>
              <Stepper
                steps={steps}
                activeStep={step}
                // connectorStyleConfig={{stepSize:'3em'}} doesn't seem to be working atm
                styleConfig={{
                  size: "1.3em",
                  activeBgColor: "#007bff",
                  completedBgColor: "#0061c9",
                  shape: "circle",
                  color: "#fff",
                }}
                style={{ padding: "5px" }}
              />
            </span>
          )} */}
        </h5>
        <h6 className='card-subtitle mb-4 text-muted w-50'>{subtitle}</h6>
        {children}
      </div>
    </div>
  );
}

// CardA.defaultProps = {
//   icon: "",
//   title: "",
//   subtitle: "",
//   help: "",
//   step: "",
// };

import {
  CoraFinding,
  CoraSampleTest,
  WasatchFinding,
  WasatchSampleTest,
} from "../interfaces/sample";

export const modelLibraryFilter = (finding: CoraFinding | WasatchFinding) => {
  try {
    if (!finding || !finding.libraryName) {
      throw new Error("Finding object is missing libraryName property");
    }
    return finding.libraryName.toLowerCase().includes("model");
  } catch (error) {
    console.error("Error in modelLibraryFilter:", error);
    return false; // You can choose to handle the error differently if needed.
  }
};

export function getFilteredFindings(
  findings: Array<WasatchFinding | CoraFinding>
): Array<WasatchFinding | CoraFinding> {
  try {
    if (!findings || !Array.isArray(findings) || findings.length === 0) {
      throw new Error("Invalid input. Expected a non-empty array of findings.");
    }

    findings = findings.filter(modelLibraryFilter);

    if (findings[0] && findings[0].hasOwnProperty("return_result")) {
      // Handling Wasatch findings
      return findings.filter(
        (finding: WasatchFinding) => finding.return_result
      );
    }
    // Handling Cora findings (and Wasatch findings that didn't use the binary model)
    return findings;
  } catch (error) {
    console.error("Error in getFilteredFindings:", error);
    return []; // Return an empty array or handle the error as needed.
  }
}

import { Modal, Button, Form, ModalBody, ModalFooter } from "react-bootstrap";

const ReachbackModal = ({ showModal, handleClose, finding, scanType }) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
      size='lg'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {scanType} Findings: {finding}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex align-items-center mb-2'>
          <i
            className='bi bi-exclamation-triangle-fill'
            style={{
              fontSize: "large",
              color: "#AE0000",
              marginRight: "10px",
              marginBottom: "2px",
            }}
          ></i>
          {/* <span> */}
          <span
            style={{
              color: "#AE0000",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Displayed results are currently undergoing expert review and should
            be interpreted cautiously.
          </span>
        </div>
        <div className='d-flex align-items-center'>
          <i
            className='bi bi-clock-fill'
            style={{
              fontSize: "large",
              color: "#F08021",
              marginRight: "10px",
              marginBottom: "2px",
            }}
          ></i>
          {/* <span> */}
          <span style={{ color: "#F08021", fontWeight: "bold" }}>
            Reachback Submitted - We'll be in touch shortly!
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ReachbackModal;

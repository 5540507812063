import React from "react";
//import { BrowserRouter } from "react-router-dom";

import Body from "../components/Body";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Home = () => {
  return (
    <div>
      <Navbar signedIn={false} />
      <Body />
      <Footer />
    </div>
  );
};
export default Home;

import React from "react";
import { CardA } from "../../ui/Cards";

const WizardCalibrateFail = ({ step, steps, changeStep, pageID }) => {
  if (step !== pageID) {
    return null;
  }
  return (
    <CardA
      title='Calibration Failed'
      subtitle='Adjust focal length of adapter and redo calibration'
      // steps={steps}
      // step={step}
    >
      <div className='row mt-5'>
        <button
          type='button'
          className='btn btn-warning mx-auto w-25'
          onClick={() => changeStep("scan")}
        >
          Redo Calibration
        </button>
        {/* <button
          type="button"
          className="btn btn-primary mx-auto w-25"
          onClick={() => changeStep("forward")}
        >
          Forward
        </button> */}
      </div>
    </CardA>
  );
};

export default WizardCalibrateFail;

import React, { useEffect, useState } from "react";
import { CardA } from "../../ui/Cards";
import { useConnectorCalibrate, useServerCalibrate } from "../../../api";
import { useSampleContext } from "../../../contexts";

const WizardCalibrateScan = ({ step, steps, changeStep, pageID }) => {
  const { device } = useSampleContext();
  const connectorCalibration = useConnectorCalibrate(
    device.deviceId,
    onConnectorSuccess,
    errorLogger
  );
  const serverCalibration = useServerCalibrate(
    device.deviceId,
    Date.now(),
    onServerSuccess,
    errorLogger
  );
  const [loading, setLoading] = React.useState(false);

  function errorLogger(err) {
    console.error(err);
    setLoading(false); // deactivate loading spinner
    changeStep("fail");
  }

  // Callback used when the connector query finishes to check the results
  function onConnectorSuccess(data) {
    if (data) {
      // Check the result response code of the connector
      if (data.status && data.status >= 200 && data.status < 300) {
        // only 200s pass
        console.log("Connector calibration successful, query the server next.");
        serverCalibration.refetch();
      } else {
        console.log("Connector calibration failed.");
        setLoading(false); // deactivate loading spinner
        changeStep("fail");
      }
    } else {
      console.log("No connector calibration data found.");
    }
  }

  // Callback used when the server query finishes to check the results
  function onServerSuccess(data) {
    if (data) {
      setLoading(false); // deactivate loading spinner
      if (data.status && data.status >= 200 && data.status < 300) {
        // only 200s pass
        console.log("Server calibration successful. Results saved.");
        changeStep("success");
      } else {
        console.log("Server calibration failed.");
        changeStep("fail");
      }
    } else {
      console.log("No server calibration data found.");
    }
  }

  if (step !== pageID) {
    return null;
  }

  // Gets the calibration response from the connector app, then saves the
  // date and time (if it was successful) to the server
  const calibrateDevice = () => {
    if (device.deviceId) {
      setLoading(true); // activate loading spinner
      connectorCalibration.refetch();
    } else {
      console.error("WizardCalibrateScan.js - No device id to calibrate.");
    }
  };

  return (
    <CardA
      title='Calibration Scan'
      subtitle={`Keep the calibration chip in the reader for "${device.name}".`}
      // steps={steps}
      // step={step + 1}
    >
      <div className='row mt-5'>
        <button
          type='button'
          className='col-4 btn btn-secondary mx-auto w-25'
          onClick={() => changeStep("return")}
        >
          Back
        </button>
        <button
          type='button'
          className='btn btn-success col-4 mx-auto w-25'
          onClick={calibrateDevice}
          disabled={loading}
        >
          {loading ? (
            <span>
              <span
                class='spinner-border spinner-border-sm'
                aria-hidden='true'
              ></span>
              <span> Loading...</span>
            </span>
          ) : (
            <span>Start Calibration</span>
          )}
        </button>
      </div>
    </CardA>
  );
};

export default WizardCalibrateScan;

import React, { useState } from "react";
import { useQueryClient } from "react-query";

import SampleCons from "../../constants/sample";

// global context for state management
import { useSampleContext } from "./../../contexts";

// components
import { CardA } from "./../ui/Cards";
import { Sequence } from "./../ui/Sequence";

// lodash to help with object comparisons
const some = require("lodash.some");

const WizardTrace = ({ step, steps, stepAction, changeStep, pageID }) => {
  const queryClient = useQueryClient();

  const [scanButtonText, setScanButtonText] = useState("Scan completed");
  const [completed, setCompleted] = useState(false);

  const { sample, traces, addTrace, removeTrace } = useSampleContext();

  const traceToggle = () => {
    setCompleted(true);
    setScanButtonText("Scan completed");
  };

  const handleNext = () => {
    changeStep("forward");
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries("sequence");
  };

  if (step !== pageID) {
    return null;
  }

  if (
    step === pageID &&
    SampleCons.TRACE_SUPPORTED.indexOf(sample.presumed) < 0
  ) {
    changeStep(stepAction);
  }

  return (
    <CardA
      title='Trace Scan'
      subtitle='Perform a trace scan'
      steps={steps}
      step={step - 1}
    >
      <div>
        <button
          className='btn btn-primary'
          type='button'
          variant='outline-primary'
          onClick={() => traceToggle()}
          disabled={completed}
        >
          {scanButtonText}
        </button>
        <button
          type='button'
          className='btn btn-outline-secondary mx-3'
          onClick={handleRefresh}
        >
          Refresh
        </button>
        <button
          className='btn btn-link'
          type='button'
          onClick={() => changeStep("forward")}
        >
          Skip trace scan
        </button>
      </div>
      {completed && (
        <form className='mt-3'>
          <p>Select the sequence(s) corresponding to the scan:</p>
          {traces.map((seq) => {
            return (
              <Sequence
                key={seq.sequenceId || seq.scanId}
                sequence={seq}
                checked={some(sample.trace, seq)}
                add={addTrace}
                remove={removeTrace}
              />
            );
          })}
        </form>
      )}
      <div className='row mt-4'>
        <button
          type='button'
          className='col-4 btn btn-secondary mx-auto w-25'
          onClick={() => changeStep("back")}
        >
          Back
        </button>
        {completed && (
          <button
            type='button'
            className='col-4 btn btn-primary mx-auto w-25'
            onClick={() => handleNext()}
          >
            Continue
          </button>
        )}
      </div>
    </CardA>
  );
};

export default WizardTrace;

import React, { useEffect, useState } from "react";

// api calls

// contexts
import { useSampleContext } from "./../../contexts";

// components
import { CardA } from "./../ui/Cards";
import { ColourCircleB } from "./../ui/ColourCircle";
import { SequenceB } from "./../ui/Sequence";

import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const WizardSummary = ({ step, steps, changeStep, pageID }) => {
  const { sample, flow } = useSampleContext();

  const [submit, setSubmit] = useState("");
  const [sampleID, setSampleID] = useState(null);

  const history = useHistory();

  const getBulk = () => {
    const bulkSeq = [];
    for (let i = 0; i < sample.bulk.length; i++) {
      bulkSeq.push({
        deviceId: sample.bulk[i].deviceId,
        sequenceId: sample.bulk[i].sequenceId,
        scanType: "bulk-burst",
      });
    }
    return bulkSeq;
  };

  const getTrace = () => {
    const traceSeq = [];
    for (let i = 0; i < sample.trace.length; i++) {
      traceSeq.push({
        deviceId: sample.trace[i].deviceId,
        sequenceId: sample.trace[i].sequenceId,
        scanType: "trace-burst",
        flow: flow,
      });
    }
    return traceSeq;
  };

  // const handleReset = () => {
  //   setSubmit("");
  //   reset();
  //   changeStep("reset");
  // };

  async function handleSubmit() {
    const summary = {
      tested: sample.tested,
      presumedSubstance: sample.presumed,
      colour: sample.colour[1],
      bulkSeq: getBulk(),
      traceSeq: getTrace(),
    };
    await axios
      .post("/companion/new-sample/submit-sample", summary)
      .then(function (res) {
        console.log(res);
        setSampleID(res.data);
        setSubmit("success");
      })
      .catch((error) => handleError(error));
    // submitSample(summary);
    // setSubmit(true);
  }

  function handleError(error) {
    console.log(error);
    setSubmit("error");
  }

  useEffect(() => {
    if (submit === "error") {
      let timer = setTimeout(() => setSubmit(""), 3000);

      // this will clear the timeout
      return () => {
        clearTimeout(timer);
      };
    }
    if (submit === "success" && sampleID) {
      let timer = setTimeout(() => {
        setSubmit("");
        history.push(`/sample/${sampleID}`);
        // handleReset();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [submit, sampleID, history]);

  if (step !== pageID) {
    return null;
  }

  return (
    <div>
      <CardA title='Sample Summary' steps={steps} step={step - 1}>
        <div className='ms-4 my-5'>
          <table className='table'>
            <tbody>
              <tr>
                <th>Presumed substance</th>
                <td>{sample.presumed}</td>
              </tr>
              <tr>
                <th>Colour</th>
                <td>
                  <ColourCircleB
                    colour={sample.colour[0]}
                    label={sample.colour[2]}
                  />
                </td>
              </tr>
              <tr>
                <th>Bulk scan(s)</th>
                <td>
                  <div className='row'>
                    {sample.bulk.map((item, index) => {
                      return (
                        <div key={index} className='col-lg-6'>
                          <SequenceB
                            findings={item.findings}
                            sequenceId={item.sequenceId || item.scanId}
                          />
                        </div>
                      );
                    })}
                  </div>
                </td>
                {/* <td>345, 346</td> */}
              </tr>
              <tr>
                <th>Trace scan(s)</th>
                <td>
                  <div className='row'>
                    {sample.trace.map((item, index) => {
                      return (
                        <div key={index} className='col-lg-6'>
                          <SequenceB
                            findings={item.findings}
                            sequenceId={item.sequenceId || item.scanId}
                          />
                        </div>
                      );
                    })}
                  </div>
                </td>
              </tr>
              <tr>
                <th>Tested</th>
                <td>{sample.tested === "" ? "N/A" : `${sample.tested}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {submit === "error" && (
          <div className='alert alert-danger' role='alert'>
            Something went wrong, please try again ...
          </div>
        )}
        {submit === "success" && (
          <div className='alert alert-success' role='alert'>
            Sample submitted! You will be redirected in 3 seconds...
          </div>
        )}
        {
          <div className='row'>
            <button
              type='button'
              className='col-4 btn btn-secondary mx-auto w-30'
              onClick={() => changeStep("back")}
            >
              Back
            </button>
            <button
              type='button'
              className='col-4 btn btn-success mx-auto w-30'
              onClick={() => handleSubmit()}
            >
              Submit for analysis
            </button>
          </div>
          // )
        }
      </CardA>
    </div>
  );
};

export default WizardSummary;

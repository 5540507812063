import React, { useState } from "react";

//contexts
import { useSampleContext } from "../../contexts";
import { useNewColours } from "./../../api";

//components
import { CardA } from "../ui/Cards";
import { Alert } from "antd";
import "antd/dist/antd.css";

import { ColourSquare } from "./../ui/ColourCircle";

import SampleCons from "../../constants/sample";

const WizardSampleInfo = ({ step, steps, changeStep, pageID }) => {
  const { sample, wasatchSample, setPresumedSubstance, flow, setColour } =
    useSampleContext();
  const [warning, setWarning] = React.useState(<></>);
  const [selectedPresumed, setSelectedPresumed] = useState(
    flow === "wasatch" ? wasatchSample.presumed[1] : sample.presumed[1]
  );
  const [selectedColour, setSelected] = useState(
    flow === "wasatch" ? wasatchSample.colour[1] : sample.colour[1]
  );

  const { data, isFetching, isError } = useNewColours();

  const handleSubstanceChange = (newSubstance) => {
    setPresumedSubstance(newSubstance);
    setSelectedPresumed(newSubstance);

    if (flow !== "wasatch") {
      if (SampleCons.PRESUMED_SUBSTANCES.indexOf(newSubstance) < 0) {
        setWarning(<></>);
      } else if (SampleCons.TRACE_SUPPORTED.indexOf(newSubstance) < 0) {
        setWarning(
          <Alert
            message='Warning'
            description='This substance class is currently not supported for trace scans.'
            type='warning'
            showIcon
          />
        );
      } else {
        setWarning(<></>);
      }
    }
  };

  // if colours cannot be fetched
  if (!isFetching && isError) {
    return <h1>Cannot fetch colours</h1>;
  }

  const changeColour = (colour, id, text) => {
    setSelected(id);

    setColour([colour, id, text]);
  };

  const allColours = () => {
    return data.map((item) => {
      if (!item.label) {
        return (
          <div className='d-flex flex-row w-100 justify-content-center mt-3'>
            {item.colours.map((colour) => {
              const colourName = colour.label;
              const hexCode = colour.hexCode;
              const id = colour.id;
              return (
                <ColourSquare
                  key={id}
                  id={id}
                  colour={hexCode}
                  selected={selectedColour === id}
                  updateColour={changeColour}
                  text={colourName}
                  showLabel={true}
                />
              );
            })}
          </div>
        );
      } else {
        const colourName = item.label;
        return (
          // <div className='d-flex flex-row w-75 justify-content-center'>
          <div className='d-flex flex-column w-25 mx-2 mb-3 font-weight-bold text-center '>
            {colourName}
            <div className='d-flex flex-row w-100 justify-content-center '>
              {item.colours.map((colour) => {
                const hexCode = colour.hexCode;
                const id = colour.id;
                return (
                  <ColourSquare
                    key={id}
                    id={id}
                    colour={hexCode}
                    selected={selectedColour === id}
                    updateColour={changeColour}
                    text={colourName}
                  />
                );
              })}
            </div>
          </div>
          // </div>
        );
      }
    });
  };

  if (step !== pageID) {
    return null;
  }
  return (
    <CardA
      title='Sample Information'
      // subtitle='Choose the presumed substance from the list below'
      steps={steps}
      step={step - 1}
      // styleConfig={{
      //   size: "1.3em",
      //   activeBgColor: "#007bff",
      //   completedBgColor: "#0061c9",
      //   shape: "circle",
      //   color: "#fff",
      // }}
    >
      <div className='row'>
        <div className='col'>
          <h6>Presumed substance</h6>
          <h6 className='card-subtitle mb-4 text-muted'>
            Choose the presumed substance from the list below
          </h6>
          {/* <select
            className='form-control mb-2'
            value={
              flow === "wasatch" ? wasatchSample.presumed : sample.presumed
            }
            onChange={(e) => handleSubstanceChange(e.target.value)}
          >
            <option value=''>Select presumed substance</option>
            {SampleCons.PRESUMED_SUBSTANCES.map((substance) => {
              return <option value={substance}>{substance}</option>;
            })}
          </select> */}
          <div className='row justify-content-center'>
            <div className='accordion w-75 ' id='accordionExample'>
              {SampleCons.NEW_PRESUMED_SUBSTANCES.map((substance) => {
                let indexOfSpace = substance.label.indexOf(" ");
                let newLabel = substance.label.slice(0, indexOfSpace);

                let isOpen = substance.drugs.includes(selectedPresumed);

                return (
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id={substance.label}>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#${newLabel}-collapse`}
                        aria-expanded={String(isOpen)}
                        aria-controls={`${newLabel}-collapse`}
                      >
                        {substance.label}
                      </button>
                    </h2>

                    <div
                      id={`${newLabel}-collapse`}
                      className={
                        isOpen
                          ? "accordion-collapse collapse show"
                          : "accordion-collapse collapse"
                      }
                      aria-labelledby={newLabel}
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        {substance.drugs.map((drug) => {
                          let isChecked = false;
                          if (selectedPresumed === drug) {
                            isChecked = true;
                          }
                          return (
                            <div className='form-check'>
                              <input
                                type='radio'
                                className='form-check-input'
                                name='drugs-radio'
                                id={drug}
                                checked={isChecked}
                                onChange={() => handleSubstanceChange(drug)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor={drug}
                              >
                                {drug}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {warning}
        </div>
        <div className='col'>
          <h6>Sample Colour</h6>
          <h6 className='card-subtitle mb-4 text-muted'>
            This will not impact your final results.
          </h6>
          <div className='d-flex flex-wrap mx-auto justify-content-center'>
            {allColours()}
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <button
          type='button'
          className='col-4 btn btn-secondary mx-auto w-30'
          onClick={() => changeStep("back")}
        >
          Back
        </button>
        <button
          type='button'
          className='col-4 btn btn-primary mx-auto w-30'
          onClick={() => changeStep("forward")}
          disabled={
            !(flow === "wasatch"
              ? wasatchSample?.presumed
              : sample?.presumed) ||
            !(flow === "wasatch" ? wasatchSample?.colour[1] : sample?.colour[1])
          }
        >
          Continue
        </button>
      </div>
    </CardA>
  );
};

export default WizardSampleInfo;

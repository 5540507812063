import React, { useEffect } from "react";

//contexts
import { useSampleContext } from "./../../contexts";

import { useConnectorDevices, useServerDevices } from "../../api";
import styleDate from "../../util/dateStyler";

//components
import { CardA } from "./../ui/Cards";
import "antd/dist/antd.css";

import SampleCons from "../../constants/sample";

const WizardDeviceSelection = ({
  step,
  steps,
  isCalibrate,
  changeStep,
  pageID,
}) => {
  const [warning, setWarning] = React.useState(<></>);
  const [devices, setDevices] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("No devices found.");
  const { setCalibrationDevice } = useSampleContext();
  const connectorDevices = useConnectorDevices(
    reloadServerDevices,
    errorLogger
  );
  const serverDevices = useServerDevices(
    connectorDevices.data,
    setDevicesParser,
    errorLogger
  );

  /**
   * Every time the step is updated, check if this page was loaded
   * and refresh the devices if needed.
   * Note: this call isn't immediately after the step is updated (there is a
   * slight delay). As such, the previous device cards will appear for a
   * fraction of a second before the reload occurs if the page is switched to.
   * As such, devices are set to 'undefined' when the user changes pages to
   * prevent this.
   **/
  useEffect(() => {
    // Start a timer
    const timer = setTimeout(() => {
      if (step === pageID) {
        reloadConnectorDevices();
      }
    }, 500); // Delay in milliseconds

    // Clear the timer when the component unmounts or when step or pageID changes
    return () => clearTimeout(timer);
  }, [step, pageID]);

  const handleDeviceChange = (device) => {
    console.log(device);
  };

  function errorLogger(err) {
    console.error(err);
    setDevices([]);
    setLoading(false); // deactivate loading spinner
  }

  function reloadConnectorDevices() {
    console.log("Reloading connector devices");
    setLoading(true); // activate loading spinner
    connectorDevices.refetch();
  }

  function reloadServerDevices(response) {
    if (
      response &&
      response.status &&
      response.status >= 200 &&
      response.status < 300
    ) {
      // console.log("Reloading server devices");

      serverDevices.refetch();
    } else {
      console.error("Could not retrieve devices from connector app.");
      setDevices([]);
      if (response && response.message) {
        setErrMsg(response.message);
      } else {
        setErrMsg("No devices found.");
      }
      setLoading(false); // deactivate loading spinner
    }
  }

  function setDevicesParser(serverDevices) {
    if (serverDevices && serverDevices.length > 0) {
      // Sort the devices by last calibrated date
      serverDevices.sort((a, b) => {
        return b.lastCalibration - a.lastCalibration;
      });
      setDevices(serverDevices);
      setLoading(false); // deactivate loading spinner
    } else {
      setDevices([]);
      setLoading(false); // deactivate loading spinner
      setErrMsg(
        "Could not find devices returned by connector application registered on the server."
      );
    }
  }

  if (step !== pageID || isCalibrate) {
    return null;
  }

  return (
    <CardA
      title='Select a Device'
      subtitle='Click the button that corresponds to your device'
      steps={steps}
      step={step - 1}
    >
      {warning}
      <div className='d-flex flex-column gap-3'>
        {
          // While the devices are being fetched, show a loading spinner
          loading && (
            <div className='mx-auto'>
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          )
        }
        {
          // Check that the second fetch (to the server) has finished
          // Return the JSX for device cards, where each device in the array gets a card (if they exist)
          // Also modify the cards if the calibration date has expired
          !loading &&
            devices &&
            devices.length > 0 &&
            devices.map((d) => (
              <CardA
                key={d.deviceId}
                title={d.name}
                subtitle={`Last calibrated: ${
                  d.lastCalibration ? styleDate(d.lastCalibration) : "Never"
                }`}
                style='mt: 1em'
              >
                {d.lastCalibration >= new Date(Date.now() - 3600000 * 24) ? (
                  <div className='row mt-5'>
                    <button
                      type='button'
                      className='btn btn-success mx-auto w-25'
                      onClick={() => {
                        setCalibrationDevice(d);
                        setDevices(undefined);
                        changeStep("forward");
                      }}
                    >
                      Start
                    </button>
                  </div>
                ) : (
                  <div className='row mt-5'>
                    <button
                      type='button'
                      className='btn btn-warning mx-auto w-25 '
                      onClick={() => {
                        setCalibrationDevice(d);
                        setDevices(undefined);
                        changeStep("calibrate");
                      }}
                    >
                      Calibrate
                    </button>
                  </div>
                )}
              </CardA>
            ))
        }
        {
          // Check if no devices were found, state so if true
          devices && devices.length === 0 && !loading && <h6>{errMsg}</h6>
        }
      </div>
      <div className='row mt-5'>
        <button
          type='button'
          className='col-4 btn btn-primary mx-auto w-25'
          onClick={() => {
            reloadConnectorDevices();
          }}
          //   disabled={!sample?.Device}
        >
          Refresh
        </button>
      </div>
    </CardA>
  );
};

export default WizardDeviceSelection;

// import React, { useState } from "react";
import { getFilteredFindings } from "../../helpers/samples";

// import { Collapse } from "antd";
import { Line } from "react-chartjs-2";
// Bootstrap
import "bootstrap-icons/font/bootstrap-icons.css";
import styleDate from "../../util/dateStyler";

import { BiCheckCircle } from "react-icons/bi";

function getSubstanceString(findings) {
  return findings
    .map(
      (finding) =>
        finding.substance.charAt(0).toUpperCase() + finding.substance.slice(1) // +
      // (finding.likelihood === undefined ? "" : ` (${finding.likelihood})`) // This prints high and low confidence for each analyte
    )
    .join(", ");
}

export function Sequence({ sequence, checked, add, remove }) {
  // const [selected, setSelected] = useState(checked)

  const handleSelect = () => {
    if (!checked) {
      add(sequence);
    } else {
      remove(sequence.sequenceId);
    }
  };

  return (
    <label
      className={`card my-3 w-50 shadow-sm ${
        checked ? "border-2 border-primary" : ""
      }`}
      onClick={() => handleSelect()}
    >
      <div className='card-body row'>
        <div className='col-auto me-auto'></div>
        <div className='col'>
          <span className='row'>Date: {styleDate(sequence.date)}</span>
          <span className='row'>Sequence ID: {sequence.sequenceId}</span>
          <span className='row'>
            {"Findings: "}
            {sequence.findings.length > 0 &&
            typeof sequence.findings[0] === "string"
              ? sequence.findings.map((item) => item + " ")
              : getSubstanceString(getFilteredFindings(sequence.findings))}
          </span>
        </div>
      </div>
    </label>
  );
}

export function SequenceB({ findings, sequenceId, pureSubstance }) {
  return (
    <div className='border bg-light p-2'>
      <div>
        <ul className='font-weight-bold align-middle mb-0'>
          {Array.isArray(findings) ? (
            findings.length > 0 && typeof findings[0] === "string" ? (
              findings.map((finding, index) =>
                pureSubstance && pureSubstance[index] === "Yes" ? (
                  <li key={index} className=''>
                    {finding} <i className=' text-success'>*High purity</i>
                  </li>
                ) : (
                  <li key={index}>{finding}</li>
                )
              )
            ) : (
              getFilteredFindings(findings).map((finding, index) => (
                <li key={index}>{getSubstanceString([finding])}</li>
              ))
            )
          ) : (
            <li>No findings available</li>
          )}
        </ul>
        {/* <small className="font-weight-bold">Sequence {sequenceId}</small> */}
      </div>
    </div>
  );
}

export function SequenceC({ adverse, checked, add, remove, prevSelect }) {
  const handleSelect = () => {
    if (!checked && !prevSelect) {
      add(adverse);
    }
    if (checked || prevSelect) {
      remove(adverse.id);
    }
  };

  return (
    <div
      className={`btn btn-light m-2 p-3 shadow-sm ${
        checked ? "border-2 border-primary" : ""
      } ${prevSelect ? "border-2 border-success" : ""}`}
      onClick={() => handleSelect()}
    >
      <span className=''>{adverse.label}</span>
    </div>
  );
}

export function SequenceD({ reachback, findings, setModalVisible }) {
  // console.log(findings)
  return (
    <div className='border bg-light p-2'>
      <ul className='font-weight-bold align-middle mb-0'>
        {Array.isArray(findings) ? (
          findings.length > 0 && typeof findings[0] === "string" ? (
            findings.map((finding, index) => (
              <p className='mb-0 font-weight-bold'>
                <li key={index}>
                  {finding}{" "}
                  {reachback && (
                    <button
                      className='btn btn-link ms-1 p-0'
                      aria-label='Info'
                      onClick={(event) => {
                        event.preventDefault();
                        setModalVisible(finding);
                      }}
                    >
                      <i
                        className='bi bi-info-circle-fill'
                        style={{
                          fontSize: "15px",
                          color: "#F08021",
                          marginBottom: "2px",
                        }}
                      ></i>
                    </button>
                  )}
                </li>
              </p>
            ))
          ) : (
            getFilteredFindings(findings).map((finding, index) => (
              <p>
                {reachback && (
                  <i
                    className='bi bi-circle-fill'
                    style={{
                      fontSize: "small",
                      color: "orange",
                    }}
                  >
                    {" "}
                  </i>
                )}
                {getSubstanceString([finding])}
                {reachback && (
                  <button
                    className='btn btn-link ms-1 p-1'
                    aria-label='Info'
                    onClick={(event) => {
                      event.preventDefault();
                      setModalVisible(finding);
                    }}
                  >
                    <i
                      className='bi bi-info-circle-fill'
                      style={{
                        fontSize: "large",
                        color: "#1CA4EE",
                        marginBottom: "2px",
                      }}
                    ></i>
                  </button>
                )}
              </p>
            ))
          )
        ) : (
          <li>No findings available</li>
        )}

        {/* <small className="font-weight-bold">Sequence {sequenceId}</small> */}
      </ul>
    </div>
  );
}

export function SequenceE({ sequence, valid, onSelect, isSelected }) {
  // const errorFlag = sequence.subScans[0]?.errorCheck?.errorFlag || 0;

  // Determine if it's a good scan quality
  let isGoodScanQuality = true;
  sequence.errorCheck?.errorFlag === 1
    ? (isGoodScanQuality = false)
    : (isGoodScanQuality = true);

  console.log("SequenceE - sequence:", sequence);
  const handleSelect = () => {
    onSelect(sequence);
  };

  // Round the wavenumber_plot values to the nearest 100s
  const roundedWavenumbers = sequence.wavenumber_plot.map(
    (value) => Math.round(value / 100) * 100
  );

  const spectraChartData = {
    labels: roundedWavenumbers,
    datasets: [
      {
        label: "Spectra Scan",
        data: sequence.processed_data_plot,
        fill: false,

        borderColor: "#0093EA",
        tension: 0.1,
      },
    ],
  };

  const graphOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Raman shift (cm^-1)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Intensity (a.u.)",
        },
      },
    },
    elements: {
      point: {
        radius: 1,
      },
    },
    plugins: {
      legend: {
        align: "end",
        display: true,
        labels: {
          boxWidth: 10,
        },
      },
    },
  };

  return (
    <label
      className={`card w-50 shadow-sm ${
        isSelected ? "border-5 border-secondary" : ""
      }`}
      onClick={handleSelect}
    >
      <div className='card-body row'>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <strong>Device:&nbsp; </strong> {sequence.deviceSerial}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <strong>Scan Number:&nbsp; </strong> {sequence.scanId}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <strong>Date:&nbsp; </strong> {styleDate(sequence.date)}
        </div>
        <div style={{ maxWidth: "100%", height: "250px" }}>
          <Line data={spectraChartData} options={graphOptions} />
        </div>
      </div>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        {isGoodScanQuality && (
          <div className='card-body row'>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // marginTop: "30px",
              }}
            >
              <BiCheckCircle // Green checkmark icon
                style={{
                  fontSize: "x-large",
                  color: "green",
                  marginRight: "10px",
                }}
              />
              <strong>Good scan! Submit for analysis</strong>
            </div>
          </div>
        )}
        {/* {sequence.subScans[0]?.errorCheck?.signalQuality ===
          "Out of library" && (
          <div className="card-body row">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <i
                className="bi bi-exclamation-triangle-fill"
                style={{
                  fontSize: "large",
                  color: "yellow",
                  marginRight: "10px",
                  marginBottom: "2px",
                }}
              ></i>
              <strong>Warning:&nbsp; </strong> Medium Scan Quality
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "7px",
                marginLeft: "28px",
              }}
            >
              <strong>Reason:&nbsp; </strong> Potential out of library substance
              or inoptimal sample insertion
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "7px",
                marginLeft: "28px",
              }}
            >
              <strong style={{ whiteSpace: "nowrap" }}>
                Possible Solution:&nbsp;{" "}
              </strong>
              Reinsert the sample, and conduct another scan. If the medium scan
              quality continues, continue to submit.
            </div>
          </div>
        )} */}

        {/* {sequence.subScans[0]?.errorCheck?.signalQuality ===
          "Non-analytical" && ( */}
        {!isGoodScanQuality && (
          <div className='card-body row'>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // marginTop: "30px",
              }}
            >
              <div
                className='bi-exclamation-square-fill'
                style={{
                  fontSize: "large",
                  color: "red",
                  marginRight: "10px",
                  marginBottom: "2px",
                }}
              ></div>
              {sequence.errorCheck?.signalQuality ===
              "out of training distrubution" ? (
                <strong>Complex spectrum. Reachback support recommended</strong>
              ) : (
                <strong>{sequence.errorCheck?.signalQuality}</strong>
              )}
            </div>
          </div>
        )}

        {/* {sequence.subScans[0]?.errorCheck?.errorFlag === 0 && ( */}
      </div>
    </label>
  );
}

export function SequenceEB({
  sequence,
  valid,
  validateResponse,
  onSelect,
  isSelected,
}) {
  const handleSelect = () => {
    onSelect(sequence);
  };

  // Round the wavenumber_plot values to the nearest 100s
  const roundedWavenumbers = sequence.wavenumber_plot.map(
    (value) => Math.round(value / 100) * 100
  );

  const spectraChartData = {
    labels: roundedWavenumbers,
    datasets: [
      {
        label: "Spectra Scan",
        data: sequence.processed_data_plot,
        fill: false,

        borderColor: "#0093EA",
        tension: 0.1,
      },
    ],
  };

  const graphOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Raman shift (cm^-1)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Intenisty (a.u.)",
        },
      },
    },
    elements: {
      point: {
        radius: 1,
      },
    },
    plugins: {
      legend: {
        align: "end",
        display: true,
        labels: {
          boxWidth: 10,
        },
      },
    },
  };

  return (
    <label
      className={`card my-3 w-50 shadow-sm ${
        isSelected ? "border-5 border-secondary" : ""
      }`}
      onClick={handleSelect}
    >
      <div className='card-body row'>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <strong>Device: </strong> {sequence.deviceSerial}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <strong>Scan Number: </strong> {sequence.scanId}
        </div>
        {/* {!valid && (
          <div className="row">
            <span className="fw-bold p-0">{sequence.errorMessage}</span>
          </div>
        )} */}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <strong>Date: </strong> {styleDate(sequence.date)}
        </div>
        <div style={{ maxWidth: "100%", height: "300px" }}>
          <Line data={spectraChartData} options={graphOptions} />
        </div>
      </div>
      {/* <div className="col-2 d-flex flex-column align-items-end justify-content-center">
        {!valid && (
          <i
            className="bi bi-exclamation-triangle-fill"
            style={{ fontSize: "large", color: "red" }}
          ></i>
        )}
      </div> */}
    </label>
  );
}

export function SequenceF({ deviceID, sequenceId, date }) {
  return (
    <div className='shadow-sm p-2'>
      <div>
        {sequenceId ? (
          <div className='row'>
            <div className='col text-muted'>{deviceID}</div>
            <div className='col text-muted'>Scan #{sequenceId}</div>
          </div>
        ) : (
          <div className='text-muted'>{deviceID}</div>
        )}
        <small className='font-weight-bold'>{date}</small>
      </div>
    </div>
  );
}

export function SequenceRadio({ flag, checked, select }) {
  return (
    <div>
      <input
        className='form-check-input'
        type='radio'
        name={flag}
        id={flag}
        checked={checked}
        onChange={() => select(flag)}
      />
      <label className='form-check-label' htmlFor={flag}>
        {flag === "" ? "N/A" : `${flag}`}
      </label>
    </div>
  );
}
export function SequenceRadioB({ name, value, id, checked, onChange }) {
  return (
    <div>
      <input
        className='form-check-input'
        type='radio'
        name={name}
        value={value}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label className='form-check-label' htmlFor={id}>
        {value === "" ? "N/A" : `${value}`}
      </label>
    </div>
  );
}

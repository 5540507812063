import React from "react";
import { FaArrowUp, FaArrowDown, FaArrowRight } from "react-icons/fa";

const NumberVsCard = ({ value, unit, vsValue, vsText, inverse }) => {
  const indicator = {
    positive: {
      colour: "#198754",
    },
    negative: {
      colour: "#AE0000",
    },
    neutral: {
      colour: "#F08021",
    },
  };

  const getPositiveColour = () => {
    if (inverse) {
      return indicator.negative.colour;
    } else {
      return indicator.positive.colour;
    }
  };

  const getNegativeColour = () => {
    if (inverse) {
      return indicator.positive.colour;
    } else {
      return indicator.negative.colour;
    }
  };

  const valueDiff = Math.round((value - vsValue + Number.EPSILON) * 100) / 100;
  let colour = indicator.neutral.colour;
  if (valueDiff > 0) {
    colour = getPositiveColour();
  } else if (valueDiff < 0) {
    colour = getNegativeColour();
  } else {
    colour = indicator.neutral.colour;
  }

  return (
    <div>
      <h3 className='my-3'>{value + unit}</h3>
      <p className='mb-0 text-muted'>
        <span className={" me-2"} style={{ color: colour }}>
          {valueDiff > 0 && <FaArrowUp />}
          {valueDiff < 0 && <FaArrowDown />}
          {valueDiff === 0 && <FaArrowRight />}
          {" " + valueDiff + unit}
        </span>
        <span className='text-nowrap'>{vsText}</span>
      </p>
    </div>
  );
};

NumberVsCard.defaultProps = {
  value: 0,
  unit: "",
  vsValue: 0,
  vsText: "",
  inverse: false,
};

export default NumberVsCard;

/**
 * Returns a string representation of the given date in the example format:
 * "Tue Jul 19 2022 3:29:35 PM ET".
 * @param {*} date 
 */
export default function styleDate(date) {
    return (new Date(date)).toLocaleString('en-US', {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "shortGeneric"
    }).replaceAll(",", "");
}
import React from "react";

// react router for routing
import { BrowserRouter, Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes/PrivateRoute.js";
import { history } from "./helpers/history";

// context for state management
import { ReachbackProvider } from "./contexts/ReachbackContext.js";

// pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import NewSample from "./pages/NewSample";
import RecentSamples from "./pages/RecentSamples";
import ViewSample from "./pages/ViewSample";
import Unauthorized from "./pages/Unauthorized";

export default function Routes() {
  return (
    <ReachbackProvider>
      {/* <Router history={history}> */}
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/" component={Home} exact />

          <Route path="/login" component={Login} exact />

          <PrivateRoute path="/new" component={NewSample} exact />

          <PrivateRoute path="/recent" component={RecentSamples} exact />

          <PrivateRoute path="/sample/:id" component={ViewSample} exact />

          <Route path="/unauthorizedError" component={Unauthorized} exact />
        </Switch>
        {/* </Router> */}
      </BrowserRouter>
    </ReachbackProvider>
  );
}

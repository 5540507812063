import React, { createContext, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Cookies from "universal-cookie";

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const UserContext = createContext();

export function UserProvider(props) {
  const cookies = new Cookies();
  const queryClient = useQueryClient();

  const [user, setUser] = useState({
    authenticated: false,
  });

  const setLocation = useMutation(
    (location, userId) =>
      axios.post("/user/post-location", {
        userId: userId,
        locationId: location,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["settings"]);
        // This will refetch every query with "byLoc" at the start of the key
        queryClient.refetchQueries("byLoc");
      },
    }
  );

  async function login(email, password) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    };
    const user = await fetch("/user/login", requestOptions).then((user) => {
      if (user.token) {
        cookies.set("JWT", JSON.stringify(user.token), {
          expires: new Date(Date.now() + 21600000),
          path: "/",
          domain: "spectraplasmonics.com",
        });
        cookies.set("type", "user", {
          expires: new Date(Date.now() + 21600000),
          path: "/",
          domain: "spectraplasmonics.com",
        });
      }
      return user;
    });
    console.log(user);
  }

  async function logout() {
    await setUser({
      authenticated: false,
    });
    console.log("User logged out");
  }

  async function changeLocation() {}

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {props.children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  const { user, login, logout } = useContext(UserContext);
  return { user, login, logout };
};

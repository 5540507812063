import axios from "axios";
import { useQuery } from "react-query";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

export const useUserId = () => {
  return useQuery("id", async () => {
    const { data } = await axios.get("/user/get-user");
    return data.id;
  },{
    retry: false
  })
}

export const useUserLocations = () => {
  return useQuery("locations", async () => {
    const { data } = await axios.get("/user/get-user");
    return data.locID;
  },{
    retry: false
  })
}

export const useUserSettings = () => {
  return useQuery("settings", async () => {
    const { data } = await axios.get("/user/get-settings");
    return data;
  },{
    retry: false
  })
}